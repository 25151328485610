import { inject, Injectable } from '@angular/core';
import { HttpRequestService } from './api/http-request.service';

import { environment } from '@environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs';

import { MockFlowService } from '@services/mock-flow.service';

const headers= new HttpHeaders({
  'Accept': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class TokenService  extends HttpRequestService {
  constructor(){ super(); }
  #mockFlowSvc = inject(MockFlowService);

  getToken() {
    const body = {
      hash: this.#mockFlowSvc.getData('hash'),
      username: environment.USER,
      password: environment.PASS
    }
    return this.http.post<any>(`${this.api}/auth/token-refresh`, body, {'headers': headers})
    .pipe(
      map(res => {
        return res
    }),
    catchError(error => {
      const code = error.status;
      const err = error.error.detail;
      throw {code, error: err};
    })
  );
  }

}
